import http from '../plugins/http';

// 需求管家自动回复
function adminAutoReply(userId, isChinese = true) {
    return Promise.all([http.axios.post('/user/getAdmin'), http.axios.get('/sysConfig/get/IMT_DEMAND_BACK')])
        .then(res => {
            if (isChinese) {
                return Promise.resolve({ id: res[0], content: res[1].value });
            } else {
                return http.axios.get('/sysConfig/get/IMT_DEMAND_BACK _EN').then(res2 => {
                    return Promise.resolve({ id: res[0], content: res2.value });
                });
            }
        })
        .then(res => {
            return http.axios.post(
                '/message/save',
                {
                    sendUserId: res.id,
                    receiveUserId: userId,
                    content: res.content
                },
                { body: 'json' }
            );
        });
}

export { adminAutoReply };
