<template>
    <div class="chatInfo" :class="{ right: isRight }">
        <el-avatar :size="48" name="chat" :src="img"></el-avatar>
        <div class="main">
            <div class="time">{{ info.time || '2020.10.12  15:30:56' }}</div>

            <div v-if="isImgChat" class="img">
                <el-image
                    :style="imgStyle"
                    :src="content"
                    fit="content"
                    :lazy="true"
                    :preview-src-list="[content]"
                    @load="loadImg"
                ></el-image>
            </div>

            <div class="content" v-else-if="!(isAdmin && contentJson)">{{ content }}</div>

            <div class="contractInfo" v-if="contentJson">
                <div class="contract-item">
                    <div class="text1">{{ $t('xing-ming') }}</div>
                    <div class="text2">{{ contentJson.nickname }}</div>
                </div>
                <div class="contract-item" v-if="sex">
                    <div class="text1">{{ $t('cheng-hu') }}</div>
                    <div class="text2">{{ sex }}</div>
                </div>
                <div class="contract-item">
                    <div class="text1">{{ $t('dian-hua') }}</div>
                    <div class="text2">{{ contentJson.phone }}</div>
                </div>
                <div class="contract-item">
                    <div class="text1">{{ $t('you-xiang') }}</div>
                    <div class="text2">{{ contentJson.email }}</div>
                </div>
                <div class="contract-item">
                    <div class="text1">{{ $t('gong-si-ming-cheng') }}</div>
                    <div class="text2">{{ contentJson.chCompanyName || contentJson.companyName }}</div>
                </div>
                <div class="contract-item">
                    <div class="text1">{{ $t('zhi-wei') }}</div>
                    <div class="text2">{{ contentJson.position }}</div>
                </div>
                <div class="contract-item" v-if="contentJson.remark">
                    <div class="text1">{{ $t('liu-yan') }}</div>
                    <div class="text2">{{ contentJson.remark }}</div>
                </div>
                <div class="contract-item" v-if="!isAdmin && !isClaim">
                    <div class="text1">{{ $t('gan-xing-qu-de-chan-pin') }}</div>
                    <div class="product-list">
                        <div
                            @click="goDetail(item)"
                            class="product-item"
                            :key="item.id"
                            v-for="item in contentJson.products"
                        >
                            <el-avatar
                                icon="el-icon-user-solid"
                                :src="item.mainPicture"
                                :size="24"
                                shape="square"
                                fit="fill"
                            ></el-avatar>

                            <div class="product-name">{{ getName(item) }}</div>
                        </div>
                    </div>
                </div>
                <div class="contract-item" v-if="contentJson.functionalRequirements">
                    <div class="text1">{{ $t('gong-neng-xu-qiu') }}</div>
                    <div class="text2">{{ contentJson.functionalRequirements }}</div>
                </div>
                <div class="contract-item" v-if="contentJson.artifact">
                    <div class="text1">{{ $t('ji-hua-jia-gong-de-gong-jian') }}</div>
                    <div class="text2">{{ contentJson.artifact }}</div>
                </div>
                <div class="contract-item" v-if="contentJson.otherProblems || contentJson.problems">
                    <div class="text1">{{ $t('ta-de-wen-ti') }}</div>
                    <div class="text2">{{ contentJson.otherProblems || contentJson.problems }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { sexModels } from '../../utils/AppState';
export default {
    name: 'chat',
    data() {
        return {
            imgStyle: {
                width: '0px',
                height: '0px'
            }
        };
    },
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        logo: {
            type: String,
            default: require('../../assets/img_defaultphoto.png')
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['userInfo']),
        isRight() {
            if (this.info.sendUserId === this.userInfo.id) {
                return true;
            } else {
                return false;
            }
        },
        img() {
            // return this.info.sendLogo
            if (this.isAdmin && !this.isRight) {
                return require('../../assets/icon_fuwuguanjia.png');
            } else {
                return this.info.sendLogo || this.logo;
            }
        },
        content() {
            if (this.info.content) {
                if (this.info.content[0] === '{') {
                    return this.isClaim
                        ? this.$t('ni-hao-wo-xiang-nin-fa-song-le-yi-tiao-qi-ye-ren-ling-xin-xi')
                        : this.$t(
                              'ni-hao-wo-dui-gui-fang-de-chan-pin-fei-chang-gan-xing-qu-xi-wang-ke-yi-xiang-xi-liao-liao'
                          );
                } else {
                    return this.info.content;
                }
            } else {
                return '';
            }
        },
        contentJson() {
            if (this.info.content) {
                if (this.info.content[0] === '{') {
                    if (this.info.content.split('=').length > 1) {
                        return null;
                    } else {
                        return JSON.parse(this.info.content);
                    }
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        sex() {
            return this.contentJson && this.contentJson.sex ? this.$t(sexModels.get(this.contentJson.sex).label) : '';
        },
        isClaim() {
            return this.contentJson && this.contentJson.companyName;
        },
        isImgChat() {
            if (this.content.indexOf('https://') !== -1) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        loadImg() {
            var img = new Image();
            img.src = this.content;
            img.onload = _img => {
                let imgWidth = img.width;
                let imgHeight = img.height;
                console.log(imgHeight);

                if (imgHeight > 200) {
                    imgWidth = (imgWidth * 200) / imgHeight;
                    imgHeight = 200;
                }
                if (imgWidth > 300) {
                    imgHeight = (imgHeight * 200) / imgWidth;
                    imgWidth = 300;
                }

                this.imgStyle = {
                    width: imgWidth + 'px',
                    height: imgHeight + 'px'
                };
            };
        },
        goDetail(info) {
            if (!info.id) {
                return;
            }
            this.$router.push({ name: 'productDetail', query: { id: info.id } });
        }
    }
};
</script>
<style lang="scss" scoped>
.chatInfo {
    display: flex;
    padding: 20px;
    &.right {
        flex-direction: row-reverse;
        .main {
            text-align: right;
            padding: 4px 10px 0 58px;

            .content {
                border-radius: 10px 0px 10px 10px;
                background-color: #0f264d;
                color: #fff;
            }

            .contractInfo {
                border-radius: 10px 0px 10px 10px;
            }

            .contract-item {
                // flex-direction: row-reverse;
                // .text1 {
                //     text-align: left;
                //     margin-left: 16px;
                //     margin-right: 0;
                // }
                // .text2 {
                //     text-align: right;
                // }

                .product-list {
                    // flex-direction: row-reverse;

                    .product-item {
                        // flex-direction: row-reverse;
                    }
                }
            }
        }
    }

    .main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 4px 58px 0 10px;

        .time {
            font-size: 12px;
            color: #bcc1cc;
            line-height: 17px;
        }

        .content {
            font-size: 12px;
            color: #000000;
            line-height: 22px;

            background: #f5f7fa;
            border-radius: 0px 10px 10px 10px;
            padding: 16px;
            margin-top: 8px;
        }

        .contractInfo {
            border-radius: 0px 10px 10px 10px;
            background: mix(#fff, rgba(255, 165, 38, 0.06), 0.5);
            border-radius: 0px 10px 10px 10px;
            border: 1px solid #ffa526;
            padding: 10px 20px;
            margin-top: 16px;
        }

        .contract-item {
            display: flex;
            align-items: center;
            height: 36px;

            .text1 {
                font-size: 12px;
                color: #878d99;
                line-height: 22px;
                text-align: right;
                min-width: 90px;
                margin-right: 16px;
            }

            .text2 {
                flex-grow: 1;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #292c33;
                line-height: 22px;

                text-align: left;
            }

            .product-list {
                flex-grow: 1;

                display: flex;

                .product-item {
                    display: flex;
                    align-items: center;

                    background: #ffffff;
                    padding: 4px 6px;
                    cursor: pointer;

                    .product-name {
                        font-size: 12px;
                        color: #292c33;
                        line-height: 22px;
                        padding: 0 6px;
                    }
                }
            }
        }
    }
}
</style>
