<template>
    <div class="chatMain">
        <manage-header />

        <el-container class="main">
            <el-aside width="2.8rem" style="overflow: hidden">
                <div
                    class="message-list scrollbar"
                    :class="{ messageAll: top > 0 }"
                    :style="{ transform: `translateY(${top}px)` }"
                >
                    <Member :isChoose="chooseId === 'system'" :info="emailInfo" type="system" />

                    <Member :isChoose="chooseId === adminId" :info="adminInfo" type="admin" />

                    <el-divider></el-divider>

                    <Member
                        v-if="item.otherUserId !== adminId"
                        :isChoose="chooseId === item.otherUserId"
                        :info="item"
                        :key="item.otherUserId"
                        v-for="item in messageList"
                    />
                </div>
            </el-aside>
            <el-main>
                <div class="container-top">
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: adminPath }">{{ $t('guan-li-zhong-xin') }}</el-breadcrumb-item>
                        <el-breadcrumb-item>{{ $t('xiao-xi-lie-biao') }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

                <div class="container-list scrollbar" ref="chatlist">
                    <chat-info
                        v-for="(item, index) in list"
                        :isAdmin="isAdmin"
                        :logo="icon"
                        :info="item"
                        :key="index"
                    ></chat-info>
                </div>

                <div class="chatMine" v-if="chooseId !== 'system'">
                    <div class="chat-input">
                        <el-input
                            v-model="message"
                            :placeholder="$t('qing-shu-ru-nei-rong')"
                            size="mini"
                            clearable
                            type="textarea"
                            resize="none"
                            limit="300"
                            autosize
                        ></el-input>
                        <el-avatar :size="48" :src="userInfo.avatar"></el-avatar>
                    </div>

                    <div class="btn">
                        <single-upload @input="imgUpload" :ratio="[1, 1]">
                            <el-button slot="main" type="text" icon="el-icon-picture" size="small">{{
                                $t('fa-song-tu-pian')
                            }}</el-button>
                        </single-upload>

                        <el-button type="warning" size="small" @click="sendMessage">{{ $t('li-ji-hui-fu') }}</el-button>
                    </div>
                </div>
            </el-main>
        </el-container>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import ManageHeader from '../components/management/Top';
import Member from '../components/email/Member';
import ChatInfo from '../components/email/ChatInfo.vue';
import { parse, differenceInMinutes } from 'date-fns';
import { adminAutoReply } from '../utils/Chat';
export default {
    name: 'chatMain',
    data() {
        return {
            loading: false,
            chooseId: 'system',
            list: [],
            message: '',
            img: '',
            top: 0
        };
    },
    computed: {
        ...mapState(['userInfo', 'adminId', 'emailInfo', 'messageList']),
        icon() {
            if (this.chooseId === 'system') {
                return require('../assets/liaotian_icon_xitong.png');
            } else {
                return '';
            }
        },
        adminInfo() {
            return (
                [...this.messageList].find(item => {
                    return item.otherUserId === this.adminId;
                }) || {
                    otherUserId: this.adminId
                }
            );
        },
        isAdmin() {
            return this.chooseId === this.adminId;
        }
    },
    components: {
        ManageHeader,
        Member,
        ChatInfo
    },
    mounted() {
        this.init();

        window.addEventListener('scroll', this.scrollEvent);
    },
    watch: {
        $route() {
            this.init();
        }
    },
    methods: {
        scrollEvent() {
            if (document.documentElement.scrollTop >= 220) {
                this.top = document.documentElement.scrollTop - 220;
            } else {
                this.top = 0;
            }
        },
        init() {
            this.getChatList();
            if (this.$route.query.otherUserId) {
                this.chooseId = Number(this.$route.query.otherUserId);
            } else {
                this.chooseId = 'system';
            }
            this.getDetail();
        },
        getChatList() {
            this.$store.dispatch('getChatList');
        },
        getDetail() {
            if (this.chooseId === 'system') {
                this.$refs.chatlist.scrollTop = 0;
                this.$http
                    .get('/email/details')
                    .then(res => {
                        this.list = res;
                        return this.$http.post('/email/readAll');
                    })
                    .then(res => {
                        this.getChatList();
                    });
            } else {
                this.$http
                    .post('/message/details', {
                        otherUserId: this.chooseId
                    })
                    .then(res => {
                        this.list = [...res];
                        this.$nextTick(() => {
                            this.$refs.chatlist.scrollTop = this.$refs.chatlist.scrollHeight;
                        });
                        let last = res.pop();
                        if (
                            last.receiveUserId === this.adminId &&
                            last &&
                            differenceInMinutes(new Date(), parse(last.time, 'yyyy-MM-dd HH:mm:ss', new Date())) < 30
                        ) {
                            console.log('刚发消息');
                            setTimeout(() => {
                                adminAutoReply(this.userInfo.id, this.isChinese).then(res => {
                                    this.getDetail();
                                });
                            }, 1000);
                        }

                        return this.$http.post('/message/batchRead', {
                            otherUserId: this.chooseId
                        });
                    })
                    .then(() => {
                        this.getChatList();
                    });
            }
        },
        imgUpload(file) {
            if (file) {
                this.send(file);
            }
        },
        sendMessage() {
            this.$nextTick(() => {
                this.send(this.message);
                this.message = '';
            });
        },
        send(content) {
            this.$http
                .post(
                    '/message/save',
                    {
                        sendUserId: this.userInfo.id,
                        receiveUserId: this.chooseId,
                        content: content
                    },
                    { body: 'json' }
                )
                .then(res => {
                    this.getDetail();
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollEvent);
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/variables';

.message-list {
    height: calc(100vh - 90px - 220px - 40px);
    overflow-y: auto;
    &.messageAll {
        height: calc(100vh - 90px - 20px - 20px);
    }
}

.main {
    @include center-content(360);
    // height: calc(100vh - 90px - 220px);
    @include max-height(80px, $type: 'height'); //  min-height: max(calc(100vh - 90px - 220px), 100px);
    padding-top: 20px;
    padding-bottom: 20px;
    // box-sizing: border-box;
}

.el-divider {
    margin: 0;
    height: 4px;
    background-color: #f5f7fa;
}
.message + .message {
    border-top: 1px solid #f2f3f5;
}

.el-main {
    margin-left: 20px;
    background-color: #fff;
    padding: 0;
}

.container-top {
    padding: 20px;
    border-bottom: 1px solid #f2f3f5;
}
.chatMine {
    padding: 0.2rem 20px 0.2rem 78px;
    // box-shadow: 0px -1px 0px 0px #f5f7fa;
}
.chat-input {
    display: flex;
    .el-avatar {
        margin: 3px 0;
    }
    .el-textarea {
        flex-grow: 1;
        margin-right: 10px;
    }
}

.btn {
    display: flex;
    justify-content: space-between;
    padding: 0.12rem 58px 0 0;
    align-items: center;
    .el-button--warning {
        min-width: 100px;
        height: 36px;
    }

    .el-button--text {
        color: #878d99;
        &:hover {
            color: #000;
        }
        display: flex;
        align-items: center;
    }
}

.el-main {
    display: flex;
    flex-direction: column;
    .container-list {
        flex-grow: 1;
        // min-height: 500px;
        overflow: auto;
    }
}
</style>

<style lang="scss">
.el-textarea {
    textarea {
        padding: 17px 15px;
    }
}

.btn {
    .el-button--text {
        .el-icon-picture {
            font-size: 20px;
        }
    }

    .single-upload {
        height: 40px !important;
    }
}
</style>
